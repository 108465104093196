var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "600" },
      model: {
        value: _vm.dialogAREdit,
        callback: function ($$v) {
          _vm.dialogAREdit = $$v
        },
        expression: "dialogAREdit",
      },
    },
    [
      _vm.carrier
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pt-0 pl-0",
                  staticStyle: { "background-color": "#1e88e5" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-4", attrs: { cols: "12" } },
                        [
                          _c("span", { staticClass: "text-h5 white--text" }, [
                            _vm._v(
                              "Accounts Receivable: " + _vm._s(_vm.carrier.name)
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "carrier-ar-form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    row: "",
                                    mandatory: "",
                                    label: "Pay To",
                                  },
                                  on: { change: _vm.changePayTo },
                                  model: {
                                    value: _vm.selectedPayTo,
                                    callback: function ($$v) {
                                      _vm.selectedPayTo = $$v
                                    },
                                    expression: "selectedPayTo",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "Factoring Company",
                                      value: "1",
                                    },
                                  }),
                                  _c("v-radio", {
                                    attrs: { label: "Carrier", value: "2" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _vm.selectedPayTo === "2"
                                ? _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: "Name",
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.name,
                                      ],
                                    },
                                    model: {
                                      value: _vm.carrier.ar_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.carrier, "ar_name", $$v)
                                      },
                                      expression: "carrier.ar_name",
                                    },
                                  })
                                : _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.factoringCompaniesList,
                                      "search-input": _vm.search,
                                      loading: _vm.factoringLoading,
                                      color: "info",
                                      clearable: "",
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Factoring Company",
                                      placeholder: "Start typing to Search",
                                      "return-object": "",
                                      required: "",
                                      rules: [
                                        (v) =>
                                          !!v ||
                                          "Factoring company is required",
                                      ],
                                      "validate-on-blur": "",
                                      "close-on-content-click": "",
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.search = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.search = $event
                                      },
                                      change: _vm.factoringSelected,
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.factoringCompany,
                                      callback: function ($$v) {
                                        _vm.factoringCompany = $$v
                                      },
                                      expression: "factoringCompany",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Address",
                                  rules: [_vm.rules.required],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-office-building ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2856080642
                                ),
                                model: {
                                  value: _vm.carrier.ar_address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_address", $$v)
                                  },
                                  expression: "carrier.ar_address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "City",
                                  rules: [_vm.rules.required],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                model: {
                                  value: _vm.carrier.ar_city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_city", $$v)
                                  },
                                  expression: "carrier.ar_city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "State",
                                  rules: [_vm.rules.required],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                model: {
                                  value: _vm.carrier.ar_state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_state", $$v)
                                  },
                                  expression: "carrier.ar_state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Zipcode",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.postalCode,
                                  ],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                model: {
                                  value: _vm.carrier.ar_zipcode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_zipcode", $$v)
                                  },
                                  expression: "carrier.ar_zipcode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.countries,
                                  label: "Country",
                                  "item-text": "name",
                                  "item-value": "id",
                                  rules: [_vm.rules.required],
                                  disabled: _vm.selectedPayTo === "1",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.carrier.ar_country,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_country", $$v)
                                  },
                                  expression: "carrier.ar_country",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Phone",
                                  rules: [_vm.rules.required, _vm.rules.phone],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-phone ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3991200581
                                ),
                                model: {
                                  value: _vm.carrier.ar_phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_phone", $$v)
                                  },
                                  expression: "carrier.ar_phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Email",
                                  rules: [_vm.rules.required],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-email ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  140416085
                                ),
                                model: {
                                  value: _vm.carrier.ar_email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_email", $$v)
                                  },
                                  expression: "carrier.ar_email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Remit Email",
                                  rules: [_vm.rules.email],
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-email-variant ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3897907503
                                ),
                                model: {
                                  value: _vm.carrier.ar_remit_email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "ar_remit_email", $$v)
                                  },
                                  expression: "carrier.ar_remit_email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Contact Name",
                                  rules: [_vm.rules.alphaNumeric],
                                  disabled: _vm.selectedPayTo === "1",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-account ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4097834680
                                ),
                                model: {
                                  value: _vm.carrier.ar_contact_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.carrier,
                                      "ar_contact_name",
                                      $$v
                                    )
                                  },
                                  expression: "carrier.ar_contact_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { type: "text", label: "Pay Terms" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "#1E88E5" } },
                                            [_vm._v(" mdi-timer-sand ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1667258827
                                ),
                                model: {
                                  value: _vm.carrier.pay_terms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "pay_terms", $$v)
                                  },
                                  expression: "carrier.pay_terms",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 pt-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "text",
                                  label: "Quickbooks ID",
                                  rules: [_vm.rules.intDigit],
                                },
                                model: {
                                  value: _vm.carrier.quickbooks_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.carrier, "quickbooks_id", $$v)
                                  },
                                  expression: "carrier.quickbooks_id",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pt-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCarrier()
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }